import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router';
import { animated, useSpring } from '@react-spring/web';
import cx from 'classnames';

import Button from '~/components/button';
import { MobileSubNavMenu } from '~/components/navigation/mobile/mobile-nav-sub-menus';
import { useSession } from '~/features/session/session-actions';
import { useLawn } from '~/features/lawns/lawn-actions';
import { ProductCategories } from '~/features/products/products';
import { getSignInUrl } from '~/utils/get-sign-in-url';
import { LawnPlanCta } from '~/components/navigation/desktop/desktop-nav';
import { PRO_SUPPLY_FLAG } from '~/feature-flags';
import * as r from '~/routes';

import styles from '~/components/navigation/mobile/mobile-nav.module.scss';
import subNavStyles from '~/components/navigation/mobile/nav-sub-menus.module.scss';

const MobileNavMenu = ({ isOpen, toggleOpen }) => {
  const { user } = useSession();
  const [subNavOpen, setSubNavOpen] = useState({
    isNavOpen: false,
    selectedMenu: '',
  });

  useEffect(() => {
    if (document?.body?.style) {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isOpen]);

  const navStyleProps = useSpring({
    transform: isOpen ? 'translateX(0%)' : 'translateX(-100%)',
  });

  const subNavStyleProps = useSpring({
    transform: subNavOpen.isNavOpen ? 'translateX(0%)' : 'translateX(-100%)',
  });

  const toggleSubNav = (selectedMenu) => {
    setSubNavOpen((o) => ({
      isNavOpen: !o.isNavOpen,
      selectedMenu,
    }));
  };

  const closeOnClick = () => {
    toggleOpen();
    setSubNavOpen({ isNavOpen: false, selectedMenu: '' });
  };

  return (
    <animated.nav
      className={styles.menu}
      style={navStyleProps}
      aria-label="Main menu"
      data-testid="mobile-nav-menu"
      onClick={(e) => {
        if (isOpen && e.target === e.currentTarget) {
          closeOnClick();
        }
      }}
    >
      <animated.nav
        className={subNavStyles.subNavWrapper}
        style={subNavStyleProps}
        aria-label="Sub menu"
      >
        <MobileSubNavMenu
          closeOnClick={closeOnClick}
          toggleSubNav={toggleSubNav}
          selectedMenu={subNavOpen.selectedMenu}
        />
      </animated.nav>

      <NavMenuLayout closeOnClick={closeOnClick}>
        <BaseMenu
          currentUser={user}
          closeOnClick={closeOnClick}
          toggleSubNav={toggleSubNav}
        />
      </NavMenuLayout>

      <CloseMenuButton onClick={closeOnClick} />
    </animated.nav>
  );
};

export default MobileNavMenu;

function NavMenuLayout({ closeOnClick, children }) {
  return (
    <div className={styles.menuLayout}>
      <div className={styles.menuHeader}>
        <Link
          className={styles.logoLink}
          to={r.homeRoute()}
          onClick={closeOnClick}
          data-heap-id="nav-home"
        >
          <img
            className={styles.homeIcon}
            src="/icons/home-link-icon.svg"
            alt="Sunday home"
            width="22"
            height="22"
          />
        </Link>
        <Link
          className={styles.logoLink}
          to={r.homeRoute()}
          onClick={closeOnClick}
          data-heap-id="nav-home"
        >
          <img
            className={styles.logo}
            src="/images/logo-black.svg?v1"
            alt="Sunday Lawn & Garden Care"
            width="300"
            height="92"
          />
        </Link>
      </div>

      <div className={styles.menuBody}>{children}</div>
    </div>
  );
}

function BaseMenu({ currentUser, closeOnClick, toggleSubNav }) {
  const location = useLocation();
  const { lawn, isFetched: isLawnFetched } = useLawn();

  return (
    <>
      <ul className={styles.basicLinkList}>
        <li>
          <Link
            className={styles.basicLink}
            to={r.customLawnPlanRoute()}
            onClick={closeOnClick}
            data-heap-id="nav-custom-lawn-plan"
          >
            Custom lawn plan
          </Link>
        </li>
        <li>
          <Link
            className={styles.basicLink}
            to={r.customPestPlanRoute()}
            onClick={closeOnClick}
            data-heap-id="nav-custom-pest-plan"
          >
            Custom pest plan
          </Link>
        </li>
        <li>
          <Link
            className={styles.basicLink}
            to={r.categoryPageRoute(ProductCategories.LAWN_CARE.slug)}
            onClick={closeOnClick}
            data-heap-id="nav-category-shop-lawn-care"
          >
            Lawn care
          </Link>
        </li>
        <li>
          <Link
            className={styles.basicLink}
            to={r.categoryPageRoute(ProductCategories.PEST_CONTROL.slug)}
            onClick={closeOnClick}
            data-heap-id="nav-category-shop-pest-control"
          >
            Pest control
          </Link>
        </li>
        <li>
          <Link
            className={styles.basicLink}
            to={r.categoryPageRoute(ProductCategories.GARDEN.slug)}
            onClick={closeOnClick}
            data-heap-id="nav-category-garden"
          >
            Garden
          </Link>
        </li>
        {PRO_SUPPLY_FLAG && (
          <li>
            <Link
              className={styles.basicLink}
              to={r.categoryPageRoute(ProductCategories.PRO_SUPPLY.slug)}
              onClick={closeOnClick}
              data-heap-id="nav-category-pro-supply"
            >
              Pro Supply
            </Link>
          </li>
        )}
        <li>
          <Button
            className={cx(styles.basicLink, styles.flyoutLink)}
            onClick={() => toggleSubNav('SHED_CONTENT')}
            data-heap-id="nav-category-shed"
          >
            The Shed blog
            <SubNavArrow />
          </Button>
        </li>
        <li>
          <LawnPlanCta
            className={styles.mobileLawnCta}
            isMobile
            lawnUuid={lawn?.uuid}
            handleClick={closeOnClick}
            user={currentUser}
            isLawnFetched={isLawnFetched}
          />
        </li>
      </ul>

      <div className={styles.footer}>
        {currentUser ? (
          <SignedInFooter
            currentUser={currentUser}
            closeOnClick={closeOnClick}
            lawn={lawn}
          />
        ) : (
          <Button
            className={styles.signInLink}
            to={getSignInUrl(location)}
            onClick={closeOnClick}
            fullWidth
            data-heap-id="nav-sign-in"
          >
            <img
              className={styles.avatar}
              src="/icons/user-avatar-icon.svg"
              alt="user icon"
              width="24"
              height="24"
            />
            <p>Sign in</p>
          </Button>
        )}
      </div>
    </>
  );
}

function SignedInFooter({ currentUser, closeOnClick, lawn }) {
  const [isSigningOut, setIsSigningOut] = useState(false);
  const { signOut } = useSession();

  const userHasCompleteLawn = currentUser && lawn?.address?.line1; // if a Lawn has a `line1` field in its `address`, they can access the Lawn Data page without issue

  const handleSignOut = async () => {
    setIsSigningOut(true);
    await signOut();

    setIsSigningOut(false);
    closeOnClick();
  };

  return (
    <>
      <div className={styles.signInLink}>
        <img
          className={styles.avatar}
          src="/icons/user-avatar-icon.svg"
          alt="user icon"
          width="24"
          height="24"
        />
        <p style={{ fontWeight: '700' }}>
          {currentUser.firstName ? `Hello, ${currentUser.firstName}` : 'Hello'}
        </p>
      </div>

      <ul className={styles.accountLinkList}>
        <li>
          <Link
            className={styles.accountLink}
            to={r.myPlanRoute()}
            onClick={closeOnClick}
            data-heap-id="nav-account-my-plan"
          >
            My plan
          </Link>
        </li>
        {userHasCompleteLawn && (
          <li>
            <Link
              className={styles.accountLink}
              to={r.lawnDataRoute()}
              onClick={closeOnClick}
              data-heap-id="nav-account-lawn-data"
            >
              Soil test results
            </Link>
          </li>
        )}
        {currentUser.hasUnpaidOrder && (
          <li>
            <Link
              className={styles.accountLink}
              to={r.nextBoxRoute()}
              onClick={closeOnClick}
              data-heap-id="nav-account-next-box"
            >
              Manage next box
            </Link>
          </li>
        )}
        <li>
          <Link
            className={styles.accountLink}
            to={r.accountSettingsRoute()}
            onClick={closeOnClick}
            data-heap-id="nav-account-account"
          >
            Account
          </Link>
        </li>

        <li>
          <Link
            className={styles.accountLink}
            to={r.referralLandingPageRoute()}
            onClick={closeOnClick}
            data-heap-id="nav-account-refer"
          >
            Refer a friend
          </Link>
        </li>
        <li>
          <Link
            className={styles.accountLink}
            to={r.helpCenterRoute()}
            onClick={closeOnClick}
            data-heap-id="nav-account-help"
          >
            Need help?
          </Link>
        </li>
      </ul>
      <div className={styles.accountFooter}>
        <Button
          variant="link"
          className={styles.accountLink}
          onClick={handleSignOut}
          isLoading={isSigningOut}
          data-heap-id="nav-account-sign-out"
        >
          Sign out
        </Button>
      </div>
    </>
  );
}

const SubNavArrow = () => (
  <div className={styles.arrowIcon}>
    <svg width="16" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.88.453L8 6.56 14.12.453 16 2.333l-8 8-8-8L1.88.453z"
        fill="#25282B"
      />
    </svg>
  </div>
);

function CloseMenuButton({ onClick }) {
  return (
    <button
      className={styles.closeMenuButton}
      type="button"
      onClick={onClick}
      aria-label="Close navigation menu"
      data-heap-id="nav-close"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 55 55"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 53 27 C 53 41 41 53 27 53 C 13 53 1 41 1 27 C 1 13 13 1 27 1 C 41 1 53 13 53 27 Z"
          stroke="var(--light-color)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="transparent"
        />
        <path
          d="M 19 19 L 35 35"
          stroke="var(--light-color)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M 35 19 L 19 35"
          stroke="var(--light-color)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
