/**
 * This is needed because Facebook's in-app browser does not support Google Sign In
 *
 * - "FBAN" (Facebook for Android/iOS)
 * - "FBAV" (Facebook for iOS)
 *
 * More info here: https://developers.googleblog.com/2020/08/guidance-for-our-effort-to-block-less-secure-browser-and-apps.html
 */
export const isFacebookBrowser = /FBAN|FBAV|Instagram|Facebook/i.test(
  navigator.userAgent
);
