export const TIERED_PLAN_DISCOUNTS_VERSIONS = {
  SMALLER_DISCOUNT: 'smaller discount',
  LARGER_DISCOUNT: 'larger discount',
};

export const TIERED_DISCOUNT_COUPONS = {
  [TIERED_PLAN_DISCOUNTS_VERSIONS.SMALLER_DISCOUNT]: {
    ONE_CATEGORY: 'BUNDLE10',
    TWO_CATEGORY: 'BUNDLE15',
    THREE_CATEGORY: 'BUNDLE20',
  },
  [TIERED_PLAN_DISCOUNTS_VERSIONS.LARGER_DISCOUNT]: {
    ONE_CATEGORY: 'BUNDLE15',
    TWO_CATEGORY: 'BUNDLE20',
    THREE_CATEGORY: 'BUNDLE25',
  },
};

export const TIERED_PLAN_DISCOUNT_BANNER_SLUG =
  'buy-more-save-more-custom-yard-plans';
