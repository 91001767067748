import { useEffect } from 'react';

import { useSession } from '~/features/session/session-actions';
import { useGoogleSignIn } from '~/hooks/use-google-sign-in';
import { isFacebookBrowser } from '~/utils/is-facebook-browser';

export const useGoogleOneTap = () => {
  const { isSignedIn, isInitializing } = useSession();
  const { isLoaded } = useGoogleSignIn();

  /**
   * Trigger the One Tap UI if:
   * - The Google Client Library has loaded
   * - The user is NOT signed in already
   */
  useEffect(() => {
    if (
      !isFacebookBrowser &&
      isLoaded &&
      window.google?.accounts?.id?.prompt &&
      !isSignedIn &&
      !isInitializing
    ) {
      window.google.accounts.id.prompt();
    }
  }, [isInitializing, isLoaded, isSignedIn]);
};
