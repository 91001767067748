import React from 'react';
import cx from 'classnames';

import { useShippingMessages } from '~/hooks/use-shipping-messages';
import { toLongMonthAndDate } from '~/utils/dates';
import { isLivePlantProduct } from '~/features/products/products';

import styles from '~/components/sunday-store/components/product-details/product-details-page.module.scss';

const shipFrequency = {
  DELAYED: 'delayed',
  IMMEDIATE: 'immediate',
  RESTRICTED: 'restricted',
};

const ShippingBadge = ({ productDetails, purchaseSku }) => {
  const { expectedShipDate: overrideExpectedShipDate, inStock } =
    productDetails;
  const { earliestShipDate, latestShipDate, isRestrictedInState } = purchaseSku;
  const { isLoadingProviders, shippingMessage } = useShippingMessages(
    purchaseSku?.skuId
  );

  if (isLoadingProviders) {
    return null;
  }

  if (!inStock || shippingMessage?.provider === 'ser') {
    return null;
  }

  const isPlantProduct = isLivePlantProduct({ productDetails });

  const today = Date.now();
  let whenToShip;

  const expectedShipDate = overrideExpectedShipDate
    ? new Date(new Date(overrideExpectedShipDate).setHours(24, 0, 0, 0)) // ensure that we're looking at midnight on the date of overrideExpectedShipDate (YYYY-MM-DD)
    : earliestShipDate && new Date(earliestShipDate);
  // ^^ If no ship dates b/c user unauth'd then return null so component doesn't render

  // Cheater way to tell if a user is logged in, "earliestShipDate" will return null if no lawn address
  if (!expectedShipDate) {
    return null;
  } else if (isRestrictedInState) {
    whenToShip = shipFrequency.RESTRICTED;
  } else if (
    // Set earliest ship date to midnight to make sure the time of day doesn't impact the comparison
    today >= expectedShipDate &&
    today <= new Date(latestShipDate).setHours(0, 0, 0, 0)
  ) {
    whenToShip = shipFrequency.IMMEDIATE;
  } else {
    whenToShip = shipFrequency.DELAYED;
  }

  const shippingText = `Ships beginning ${toLongMonthAndDate(
    expectedShipDate.toLocaleDateString()
  )} (and for free!)`;

  const shipNow = whenToShip === shipFrequency.IMMEDIATE;

  const shippingIcon = '/icons/sunday-store/shipping-box-icon.svg';

  const delayedTitle = isRestrictedInState
    ? isPlantProduct
      ? `Oh no! This plant cannot be sold in your state because it's either restricted or considered invasive.`
      : `Oh no! This item cannot be sold in your state because it's restricted.`
    : shippingText;

  return (
    <div
      className={cx(styles.shippingWrapper, {
        [styles.restricted]: whenToShip === shipFrequency.RESTRICTED,
        [styles.delayed]: whenToShip === shipFrequency.DELAYED,
      })}
    >
      <img
        className={cx(styles.shippingIcon, {
          [styles.hidden]: isRestrictedInState,
        })}
        src={shippingIcon}
        alt="Shipping box"
      />
      {!isLoadingProviders && shipNow ? (
        <div className={styles.shippingTextWrapper}>
          <p className={styles.shippingTitle}>
            Free shipping! <span>Ships in {shippingMessage?.message}</span>
          </p>
        </div>
      ) : (
        <div
          className={cx(styles.delayedShippingTextWrapper, {
            [styles.restrictedTextWrapper]: isRestrictedInState,
          })}
        >
          <p
            className={cx(styles.shippingTitle, {
              [styles.restrictedTitle]: isRestrictedInState,
            })}
          >
            {delayedTitle}
          </p>
          <p
            className={cx(styles.delayedSubtitle, {
              [styles.hidden]: isRestrictedInState,
            })}
          >
            {isPlantProduct
              ? `Growing with Sunday means ditching the guesswork. Hang tight—we'll
            ship your plant as soon as it's ready to be planted!`
              : `We are getting this product in soon! Order now to be the first to receive it.`}
          </p>
        </div>
      )}
    </div>
  );
};

export default ShippingBadge;
