import { useQuery, useQueryClient } from 'react-query';

import { api } from '~/api';
import { useLawn } from '~/features/lawns/lawn-actions';
import {
  SubscriptionStatus,
  subscriptionsToSummaries,
  subscriptionType,
} from '~/features/subscriptions/subscription';
import { useSession } from '~/features/session/session-actions';
import { useShipments } from '~/hooks/use-shipments';

export const useSubscriptionDetails = (subscriptionUuid, options = {}) => {
  const { data: subscription, ...rest } = useQuery(
    ['subscriptionDetails', subscriptionUuid],
    () => api.subscriptions.getDetailsByUuid(subscriptionUuid),
    { ...options, enabled: Boolean(subscriptionUuid) }
  );

  const activeSubscriptionItems =
    subscription && subscription.subscriptionItems
      ? subscription.subscriptionItems.filter(
          (item) => item.status === SubscriptionStatus.ACTIVE
        )
      : null;
  const scheduledSubscriptionItems = subscription
    ? subscription.subscriptionItems.filter((item) =>
        [SubscriptionStatus.SCHEDULED].includes(item.status)
      )
    : null;

  // Scheduled subscription items that are in `auth_fail` status
  const authFailScheduledSubscriptionItems = subscription
    ? subscription.subscriptionItems.filter((item) =>
        [SubscriptionStatus.AUTH_FAIL].includes(item.status)
      )
    : null;

  return {
    subscription,
    activeSubscriptionItems,
    scheduledSubscriptionItems,
    authFailScheduledSubscriptionItems,
    ...rest,
  };
};

// TODO: this needs to go away when we update <EarlyRenewalModal />
export const useSimulateRenewal = (subscriptionUuid, options = {}) => {
  const { data: purchases, ...rest } = useQuery(
    ['subscriptionRenewal', subscriptionUuid],
    () => api.subscriptions.simulateRenewal(subscriptionUuid),
    { ...options, enabled: Boolean(subscriptionUuid) }
  );

  return {
    purchases,
    ...rest,
  };
};

export const useSubscriptionsForLawn = (lawnUuid, options = {}) => {
  const { user } = useSession();
  const { shipments } = useShipments({ enabled: options.enabled });

  const { data: subscriptions, ...rest } = useQuery(
    ['subscriptionsForLawn', lawnUuid],
    () => api.subscriptions.findForLawn(lawnUuid),
    {
      ...options,
      enabled:
        typeof options.enabled !== 'undefined'
          ? Boolean(options.enabled) && Boolean(lawnUuid) && Boolean(user)
          : Boolean(lawnUuid) && Boolean(user),
    }
  );

  const lawnSubscription = subscriptions?.find((subscription) =>
    subscription?.subscriptionItems?.some(
      (subscriptionItem) =>
        subscriptionItem.planType === subscriptionType.LAWN_PLAN
    )
  );

  const lawnSubscriptions = subscriptions?.filter((subscription) =>
    subscription?.subscriptionItems?.some((subscriptionItem) =>
      [subscriptionType.LAWN_PLAN].includes(subscriptionItem.planType)
    )
  );

  const pestSubscriptions = subscriptions?.filter((subscription) =>
    subscription?.subscriptionItems?.some((subscriptionItem) =>
      [
        subscriptionType.MOSQUITO,
        subscriptionType.TOTAL_HOME,
        subscriptionType.TICK,
      ].includes(subscriptionItem.planType)
    )
  );

  const cancellableSubscriptionItems = lawnSubscription
    ? lawnSubscription.subscriptionItems.reduce(filterToCancellableSubItems, [])
    : [];

  // The logic below matches the BE logic for allowing a Lawn to be "claimed"
  // If the user does NOT have any Subscriptions OR all of their SubItems are in `complete` or `cancelled` status => allow them to claim lawn (i.e. they are NOT a current subscriber)
  const isCurrentSubscriber =
    subscriptions?.length > 0 &&
    !subscriptions?.every((subscription) => {
      return subscription?.subscriptionItems?.every(
        isSubItemCompleteOrCancelled
      );
    });

  const isCurrentPestSubscriber =
    pestSubscriptions?.length > 0 &&
    !pestSubscriptions?.every((subscription) => {
      return subscription?.subscriptionItems?.every(
        isSubItemCompleteOrCancelled
      );
    });

  const isCurrentLawnSubscriber =
    lawnSubscriptions?.length > 0 &&
    !lawnSubscriptions?.every((subscription) => {
      return subscription?.subscriptionItems?.every(
        isSubItemCompleteOrCancelled
      );
    });

  return {
    subscriptions,
    lawnSubscription,
    pestSubscriptions,
    cancellableSubscriptionItems,
    isCurrentSubscriber,
    isCurrentLawnSubscriber,
    isCurrentPestSubscriber,
    subscriptionSummaries: subscriptionsToSummaries(subscriptions, shipments),
    ...rest,
  };
};

const isSubItemCompleteOrCancelled = (subItem) =>
  [SubscriptionStatus.CANCELLED, SubscriptionStatus.COMPLETED].includes(
    subItem?.status
  );

function filterToCancellableSubItems(acc, curr) {
  if (
    ![
      SubscriptionStatus.ACTIVE,
      SubscriptionStatus.SCHEDULED,
      SubscriptionStatus.AUTH_FAIL,
    ].includes(curr.status)
  ) {
    return acc;
  }

  const existingItemIndex = acc.findIndex(
    (item) =>
      item.priceInfo.purchaseSku === curr.priceInfo.purchaseSku ||
      (item.planType === subscriptionType.LAWN_PLAN &&
        curr.planType === subscriptionType.LAWN_PLAN)
  );
  const existingItem = acc[existingItemIndex];

  if (!existingItem) {
    return [...acc, curr];
  }

  if (curr.year < existingItem.year) {
    const newAcc = [...acc];
    newAcc.splice(existingItemIndex, 1, curr);
    return newAcc;
  }

  return acc;
}

export const useSubscriptions = ({ lawn, ...options } = {}) => {
  const { user } = useSession();

  const {
    isLoading,
    error,
    data: subscriptions,
  } = useQuery(
    ['subscriptions', user?.uuid],
    () => api.subscriptions.find(user?.uuid),
    { ...options, enabled: Boolean(user?.uuid) }
  );

  // We need to accept the `lawn` from the initial hook call rather
  // than using `useLawn()` inside the hook because `useLawn()` isn't
  // smart enough to de-dupe requests yet. Once that changes we should
  // bring `useLawn()` into this hook instead
  const cancellableSubscriptions =
    subscriptions && lawn
      ? subscriptions.filter(
          (subscription) =>
            subscription.year === lawn.currentPlanYear &&
            subscription.status !== SubscriptionStatus.CANCELLED &&
            subscription.status !== SubscriptionStatus.COMPLETED
        )
      : [];

  return {
    isLoading,
    error,
    subscriptions,
    cancellableSubscriptions,
  };
};

export const useSubscriptionMutations = () => {
  const queryClient = useQueryClient();
  const { lawn } = useLawn();

  const updateSubscription = async (subscriptionUuid, updatedFields) => {
    const response = await api.subscriptions.update(
      subscriptionUuid,
      updatedFields
    );

    // Set Subscription Details query data to the update response
    queryClient.setQueryData(
      ['subscriptionDetails', subscriptionUuid],
      response
    );

    // Refetch the list of Subscriptions since the renewal price could change after update
    queryClient.invalidateQueries(['subscriptionsForLawn', lawn.uuid], {
      exact: true,
      refetchInactive: true,
      refetchActive: true,
    });

    queryClient.refetchQueries(['purchaseOrderAndShipmentHistory']);
  };

  const cancelSubscription = async (subscriptionUuid) => {
    await api.subscriptions.cancelSubscription(subscriptionUuid);

    queryClient.invalidateQueries(['subscriptionsForLawn', lawn.uuid], {
      exact: true,
      refetchInactive: true,
      refetchActive: true,
    });
  };

  return {
    updateSubscription,
    cancelSubscription,
  };
};

// TODO: this needs to go away when we update <EarlyRenewalModal />
export const useLawnSubscriptionRenewal = (options = {}) => {
  return {};
};

export const isLawnSubscriptionInAuthFail = (subscriptions = []) => {
  // get active lawn subscription items
  const lawnSub = subscriptions.find((subscription) =>
    subscription?.subscriptionItems?.some(
      (subItem) => subItem.planType === subscriptionType.LAWN_PLAN
    )
  );

  if (!lawnSub) {
    return false;
  }

  const lawnSubItems = lawnSub?.subscriptionItems.sort(
    (a, b) => b.year - a.year
  );

  const authFailYear = lawnSubItems.find(
    (subItem) => subItem.status === SubscriptionStatus.AUTH_FAIL
  )?.year;

  if (!authFailYear) {
    return false;
  }

  // if an active or completed lawn sub item is newer than an auth fail sub item,
  // then the user is not in auth fail
  return !lawnSubItems.some((subItem) => {
    return (
      (subItem.status === SubscriptionStatus.ACTIVE ||
        subItem.status === SubscriptionStatus.COMPLETED) &&
      subItem.year > authFailYear
    );
  });
};

export const getNextCommittedOrderUuid = (subscriptions = []) => {
  // get active lawn subscription items
  const lawnSub = subscriptions.find((subscription) =>
    subscription?.subscriptionItems?.some(
      (subItem) => subItem.planType === subscriptionType.LAWN_PLAN
    )
  );

  if (!lawnSub || !lawnSub.nextCommittedOrderUuid) {
    return null;
  } else {
    return lawnSub.nextCommittedOrderUuid;
  }
};
