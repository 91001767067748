import React from 'react';

import Button from '~/components/button';
import { Field, Form } from '~/components/form';
import InputField from '~/components/inputs/input-field';
import { email, required, valid } from '~/utils/validation';
import {
  getValidationErrorMessageFor,
  isExistingEmailError,
  isValidationError,
} from '~/api/api-errors';
import { captureException } from '~/utils/exception-tracking';
import { isFacebookBrowser } from '~/utils/is-facebook-browser';
import { useSession } from '~/features/session/session-actions';
import { useModal } from '~/features/modals/modal-actions';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { REGISTRATION_SOURCES } from '~/components/modals/funnel-auth/register-modal-form';

const FooterEmailCaptureForm = () => {
  const { register } = useSession();
  const { showModal } = useModal();
  const { showFlashMessage } = useFlashMessage();

  const handleSubmit = async (formValues) => {
    const email = formValues.email.toLowerCase();

    try {
      await register({
        email,
        source: REGISTRATION_SOURCES.FOOTER_NAVIGATION,
      });
    } catch (err) {
      switch (true) {
        case isExistingEmailError(err):
          return showModal('EMAIL_CAPTURE', { status: err?.email });
        case isValidationError(err):
          return {
            email: getValidationErrorMessageFor(err, 'email'),
          };
        default:
          captureException(new Error('Unhandled Registration error'), {
            extras: {
              error: err,
              isFacebookBrowser,
              source: REGISTRATION_SOURCES.FOOTER_NAVIGATION,
            },
          });
          showFlashMessage(
            'Something went wrong. Please try again later.',
            'error',
            5000
          );
      }

      return;
    }

    return showModal('EMAIL_CAPTURE', { status: 'success' });
  };

  const validateEmail = (formValues) => {
    return {
      email: valid(
        required('Email address is required'),
        email()
      )(formValues.email),
    };
  };

  return (
    <Form
      className="footer__email-input-form"
      onSubmit={handleSubmit}
      resetOnSubmit
      validate={validateEmail}
    >
      {({ isSubmitting }) => (
        <>
          <Field
            label={
              <span style={{ color: 'var(--light-color)' }}>
                Sign up for exclusive offers
              </span>
            }
            name="email"
            type="email"
            component={InputField}
            autoComplete="email"
            placeholder="Enter your email address"
          />

          <Button
            className="footer__email-submit-button"
            data-heap-id="footer-email-submit-button"
            data-testid="footer-email-submit-button"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            type="submit"
            variant="yellow"
          >
            Join now
          </Button>
        </>
      )}
    </Form>
  );
};

export default FooterEmailCaptureForm;
