export const lawnIssue = {
  WRONG_SIZE: 'WRONG_SIZE',
  PARTIAL_TREATMENT: 'PARTIAL_TREATMENT',
  ORDER_ANYWAY: 'ORDER_ANYWAY',
  USE_DRY_FERTILIZER: 'USE_DRY_FERTILIZER',
  USE_SINGLE_POUCH: 'USE_SINGLE_POUCH',
};

export const lawnSubscriptionStatus = {
  ACTIVE: 'active',
  NO_LAWN: 'no lawn',
  CANCELLED: 'cancelled',
  DO_NOT_RENEW: 'do not renew',
  DID_NOT_PURCHASE: null,
};
