export const imageUploadApi = (http) => {
  return {
    uploadImages: ({ image, userId }) => {
      const formData = new FormData();
      formData.append('images', image);

      return http.post(`/api/images/upload`, null, {
        headers: {
          'x-user-id': userId,
        },
        body: formData,
      });
    },

    getImage: (imageId) => {
      return http.get(`/api/images/${imageId}`);
    },
  };
};
