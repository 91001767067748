import { wait } from '~/utils/functions';
import { captureException } from '~/utils/exception-tracking';
import { createReadyQueue } from '~/utils/ready-queue';
import { createLogger } from '~/utils/analytics/logger';
import { createEventEmitter } from '~/utils/analytics/event-emitter';

export { EventType, DeprecatedEventType } from '~/utils/analytics/events';

const DEPLOY_ENV = window.env.DEPLOY_ENV;
const isProduction = DEPLOY_ENV === 'production';
const isLocalDev = DEPLOY_ENV === 'local';

// window.DEBUG_ANALYTICS = true; // Uncomment to see analytics-related console logs

export const createAnalyticsBeacon = (options = {}) => {
  const config = {
    delay: 0,
    getPlugins: async () => [],
    logger: createLogger({ enabled: false }),
    ...options,
  };

  const queue = createReadyQueue();
  const eventEmitter = createEventEmitter({ logger: config.logger });

  /**
   * Initialize registered plugins after the configured `delay`.
   */
  const init = async () => {
    await wait(config.delay);

    config.logger.groupCollapsed('Init');

    let asyncPlugins = [];
    try {
      asyncPlugins = await config.getPlugins();
    } catch (err) {
      captureException(new AnalyticsError('Could not load analytics plugins'), {
        level: 'info',
        extras: {
          error: JSON.stringify(err),
        },
      });
    }

    const registerPromises = asyncPlugins.map((p) => register(p));
    await Promise.all(registerPromises);
    config.logger.groupEnd('Init');

    queue.runQueue();
  };

  /**
   * Add a new plugin.
   *
   * Immediately calls the plugin's `init()` method
   * and will capture any events emitted before finished. Once `init()`
   * resolves any captured events will be replayed.
   */
  const register = async (plugin) => {
    const pluginQueue = createReadyQueue();

    runSafe(async () => {
      config.logger.info(plugin.name);
      await plugin.init();
      pluginQueue.runQueue();
    });

    Object.entries(plugin.events).forEach(([eventName, fn]) => {
      const handler = (...args) => {
        runSafe(() => {
          config.logger.info(plugin.name);
          pluginQueue.whenReady(fn)(...args);
        });
      };

      on(eventName, handler);
    });
  };

  /**
   * Trigger an event.
   *
   * Rather than emitting events directly we wrap the emitter
   * function in a `readyQueue` so that it doesn't get
   * called until our async-loaded plugins have been initialize.
   */
  const emit = (...args) => {
    const enqueuedEmit = queue.whenReady(eventEmitter.emit);
    return enqueuedEmit(...args);
  };

  /**
   * Add a single event listener.
   */
  const on = (eventName, cb) => {
    eventEmitter.on(eventName, cb);
  };

  /**
   * Run the given function, but catch any errors,
   * log them, and send to Sentry
   */
  async function runSafe(fn) {
    try {
      await fn();
    } catch (err) {
      config.logger.info(new AnalyticsError(err.message));
      captureException(new AnalyticsError(err.message), {
        extras: {
          error: err,
        },
      });
    }
  }

  return {
    init,
    register,
    emit,
    on,
  };
};

export const analyticsBeacon = createAnalyticsBeacon({
  logger: createLogger({
    enabled: () => Boolean(window.DEBUG_ANALYTICS),
    prefix: [
      '%c[Analytics]',
      'background-color: #313433; color: #f1f4f3; font-weight: bold;',
    ],
  }),
  getPlugins: async () => {
    const plugins = await import('~/utils/analytics/plugins');

    return [
      !isLocalDev && plugins.facebookPlugin,
      !isLocalDev && plugins.segmentPlugin,
      !isLocalDev && plugins.googleAdsPlugin,

      // isProduction && plugins.bingPlugin, // Turned off for the time being
      isProduction && plugins.impactPlugin,
      // isProduction && plugins.pinterestPlugin, // Turned off for the time being
      isProduction && plugins.simulmediaPlugin,
      isProduction && plugins.tinuitiPlugin,
      isProduction && plugins.tikTokPlugin,
      isProduction && plugins.tvSquaredPlugin,
      isProduction && plugins.unbouncePlugin,
    ].filter(Boolean);
  },
});

(window.topsoil = window.topsoil || {}).analytics = analyticsBeacon;

export class AnalyticsError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AnalyticsError';
  }

  toString() {
    return `AnalyticsError: ${this.message}`;
  }
}
