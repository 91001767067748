import React from 'react';
import { Link } from 'react-router';
import { RelatedProducts as AlgoliaRelatedProducts } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';

import { urlFor } from '~/sanity/images';
import Carousel from '~/components/carousels/carousel';
import LazyLoad from '~/components/lazy-load';
import { DashHeader } from '~/components/the-shed/components';
import { ALGOLIA_PRODUCTS_INDEX } from '~/components/search/shared';
import { sundayStoreProductDetailsRoute } from '~/routes';
import { IS_PRODUCTION } from '~/utils/environment';
import { useProductData } from '~/features/products/products-actions';
import { filterProducts } from '~/components/sunday-store/components/product-details/related-products';
/* import { captureException } from '~/utils/exception-tracking'; */

import styles from '~/components/the-shed/components/featured-products.module.scss';

const algoliaRecommendClient = recommend(
  window.env.ALGOLIA_APP_ID,
  window.env.ALGOLIA_API_KEY
);

const FeaturedProducts = ({ featuredProducts }) => {
  const initialProduct = featuredProducts[0];
  const { product } = useProductData({
    sku: initialProduct?.sku,
  });

  const objectID = product?.productDetails?.id;

  return (
    <div className={styles.featuredProducts}>
      {IS_PRODUCTION && Boolean(objectID) ? (
        <AlgoliaRelatedProducts
          recommendClient={algoliaRecommendClient}
          indexName={ALGOLIA_PRODUCTS_INDEX}
          objectIDs={[objectID]}
          itemComponent={FeaturedProductCarouselItem}
          view={FeaturedProductCarousel}
          headerComponent={FeaturedProductsHeader}
          maxRecommendations={8}
          transformItems={filterProducts}
          fallbackComponent={() => (
            <SanityFeaturedProducts featuredProducts={featuredProducts} />
          )}
        />
      ) : (
        <SanityFeaturedProducts featuredProducts={featuredProducts} />
      )}
    </div>
  );
};

export { FeaturedProducts };

const FeaturedProductsHeader = () => <DashHeader title={'Featured Products'} />;

const FeaturedProductCarousel = (props) => (
  <div className={styles.carouselWrapper}>
    <LazyLoad height={240}>
      <Carousel childrenPerScroll={3} buttonClassName={styles.carouselButton}>
        {props.items?.map((item) => (
          <props.itemComponent key={item.slug} product={item} />
        ))}
      </Carousel>
    </LazyLoad>
  </div>
);

const FeaturedProductCarouselItem = ({ product }) => {
  const source = urlFor(product.primaryImage)
    .fit('max')
    .height(300)
    .maxWidth(300);

  const alt = product.primaryImage.alt || product.name;

  /* Don't show the item if we don't have a category or product slug */
  if (product.categories?.[0] && product.slug) {
    return (
      <Link
        className={styles.carouselLink}
        // Note: in the Algolia fallback case, we don't have ANY category info in `product` -> wonky :category in URL (hopefully just for non-Prod envs)
        to={sundayStoreProductDetailsRoute({
          category: product.categories?.[0],
          slug: product.slug,
        })}
        aria-label={alt}
      >
        <img
          className={styles.carouselImage}
          src={source.url()}
          alt={alt}
          width="158"
          height="158"
        />

        <span className={styles.carouselProductName}>{product.name}</span>
      </Link>
    );
  }
  /* else if (IS_PRODUCTION) {
    captureException(new Error(`Invalid featured products request on `+ window.location.href), {
        level: 'info',
        extras: {
          url: window.location.href,
          referrer: document.referrer,
          productCategorySlug: product.categories?.[0],
          productSlug: product.slug
        },
        fingerprints: ['invalidFeaturedProducts', window.location.href],
      });
    return null;    
  } */
};

const SanityFeaturedProducts = ({ featuredProducts }) => {
  if (!featuredProducts?.length) {
    return null;
  }

  return (
    <>
      <FeaturedProductsHeader />
      <FeaturedProductCarousel
        items={featuredProducts}
        itemComponent={FeaturedProductCarouselItem}
      />
    </>
  );
};
