export const SoilTestType = {
  LAWN: 'law',
  GARDEN: 'gar',
};

export const soilTestTypeDisplay = (type) => {
  switch (type) {
    case SoilTestType.GARDEN:
      return 'Garden';
    case SoilTestType.LAWN:
    default:
      return 'Lawn';
  }
};

export const SOIL_TEST_DESCRIPTIONS = {
  law: {
    organic_material: `This is the amount of materials derived from organisms in your soil. OM eventually breaks down into nutrients and gets absorbed by your grass and plays a critical role in the soil's ecosystem. We like to see OM at a minimum of 2%; even higher for sandy soils.`,
    cation_exchange: `To oversimplify, this is the soil's ability to retain water and nutrients. Lower CEC means the soil is less able to hold nutrients and vice versa. CEC is very difficult to change, however, you can change your lawn care regimen to fit your soil's CEC.`,
    soil_ph: `pH impacts how easily grass can absorb certain nutrients. A perfect pH falls between 6.5-7.0; however, grass can easily tolerate anything between 4.0 - 9.0. Pursuing a perfect pH is usually unnecessary, expensive, and can take years.`,
    soluble_salts: `Soluble salts assess the risk of fertilizer burn and pet spots; the higher the number, the greater the risk. Excluding pets and burn, there's little to worry about when soluble salts are below 0.7. Above 3 can start to cause serious problems, depending on grass type.`,
    phosphorus: `A key macronutrient involved in many cellular processes and more important for newly installed lawns. Also a major pollutant, apply with caution. 21 ppm is all the Phosphorus an established lawn needs.`,
    potassium: `Another macronutrient that is vital to grass' ability to endure stress, however, it is often over-applied. Too much potassium increases the risk of certain diseases. 40 ppm is adequate for turfgrass.`,
    calcium: `Crucial for frost-hardy plants, but is abundant in 99% of US soils. It's very important, but your soil probably has enough already (only 331 ppm is needed.)  Calcium can be helpful if trying to raise pH.`,
    magnesium: `Critical nutrient for chlorophyll to function. 47 ppm is enough for grass, and only 1 out of 100 lawns have a magnesium deficiency.`,
    sulfur: `Creates beneficial amino acids and aids in new growth. 7 ppm is all your lawn needs, however, higher levels can be helpful in preventing certain diseases. Excessive sulfur can cause problems in lawns that get easily waterlogged.`,
    iron: `Grass loves iron; weeds and diseases not so much. Iron will also give grass a darker color.  Below 75 ppm is considered deficient, but we'll probably send you a little iron anyways to help prevent weeds and diseases.`,
    boron: `The most micro of the micronutrients, as your lawn only needs 0.30 ppm to be healthy. Yet, about 15% of the lawns we see are short on Boron. It plays many roles, notably in building sugars. Use caution when adding Boron, as going over 5 ppm can cause major problems.`,
    manganese: `Not to be confused with magnesium, manganese is needed for photosynthesis to work and is more sensitive to pH than other nutrients. About 5 ppm is sufficient across normal pH levels, and only about 2% of lawns have a deficiency.`,
    copper: `Copper is important for photosynthesis and some proteins. 0.3-1 ppm is all you need. Given how expensive copper is, deficiencies should be addressed with compost as opposed to copper fertilizers.`,
    zinc: `Zinc is an important ingredient for most enzymes.  Your lawn needs only 0.5-1 ppm, and deficiencies are rare on most American lawns.`,
  },
  gar: {
    aluminum: `Shows up on your soil test because it can affect plant growth in very acidic soils (below pH 5.5). Most gardens don't need to worry about aluminum levels.`,
    boron: `Helps plants grow cells and move sugars. Most soils have enough, but sandy or low organic matter soils can run short. Too much can harm plants. Brassicas and fruit trees are sensitive to low levels.`,
    chlorine: `Chlorine is important for photosynthesis, but gardens usually get what they need naturally. We don't typically add extra in fertilizers.`,
    copper: `Helps plants make proteins for strong growth. Shortages are rare, but can happen in high pH, compact, or sandy soils with high organic matter. Carrots, lettuce, and onions are sensitive to low levels.`,
    iron: `Important for plant metabolism and enzyme activity - without it, plants can't make chlorophyll. Soils with low organic matter or high pH are most at risk. Trees and ornamentals often show signs of low iron first.`,
    manganese: `Required to make chlorophyll and enzymes. Sandy or high-pH soils can run low, but soils below pH 4.5 can have too much. Garden favorites like onions, beans, and tomatoes benefit from manganese.`,
    sodium: `Too much sodium can damage your soil's structure and make it hard for plants to grow. If levels are high, good drainage and the right amendments can help.`,
    zinc: `Vital for enzyme activity. While most gardens have plenty of zinc, soils with low organic matter can occasionally run low.`,
  },
};
