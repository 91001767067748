import { useQuery } from 'react-query';

const YOTPO_API_KEY = window.env.YOTPO_API_KEY;

export const useYotpo = (sku) => {
  const { data, ...rest } = useQuery(
    ['reviews', sku],
    async () => {
      return fetch(
        `https://api-cdn.yotpo.com/v1/widget/${YOTPO_API_KEY}/products/${sku}/reviews.json`
      ).then((res) => res.json());
    },
    {
      enabled: Boolean(sku),
    }
  );

  const totalReviews = data?.response?.bottomline?.total_review || 0;
  const averageRating = data?.response?.bottomline?.average_score || 0;

  return {
    totalReviews,
    averageRating,
    ...rest,
  };
};
