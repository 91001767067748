import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import BlockContent from '~/components/layouts/block-content';
import ProductLink from '~/components/products/product-link';
import { assetUrlFor } from '~/sanity/assets';
import { urlFor } from '~/sanity/images';
import { shedArticleRoute } from '~/routes';
import { blocksToText } from '~/sanity/block-content-utils';
import { captureException } from '~/utils/exception-tracking';

import styles from '~/components/sunday-store/components/product-details/product-details-page.module.scss';

const LinkedContent = ({ linkedContent }) => {
  if (!linkedContent || !linkedContent?.linkedContentArticle) {
    return null;
  }

  const { linkedContentArticle, linkedContentFlexContent } = linkedContent;
  const doesCustomContentExist = Boolean(
    blocksToText(linkedContentFlexContent).trim()
  );

  return (
    <section className={styles.linkedContentSection}>
      <ContentImage image={linkedContentArticle.thumbnailImage} />

      <div className={styles.linkedContentBody}>
        {doesCustomContentExist ? (
          <BlockContent
            content={linkedContentFlexContent}
            serializers={serializers}
          />
        ) : (
          <ShedContent linkedContentArticle={linkedContentArticle} />
        )}
      </div>
    </section>
  );
};

export default LinkedContent;

const ContentImage = ({ image }) => {
  const { alt } = image ?? {};
  const desktopSource = urlFor(image).height(350).width(640).fit('max').dpr(2);
  const mobileSource = urlFor(image).width(375).fit('max').dpr(2);

  return (
    <picture>
      <source
        media="(max-width: 767px)"
        srcSet={`${mobileSource.url()} 375w`}
      />
      <source media="(min-width: 768px)" srcSet={desktopSource.url()} />
      <img
        alt={alt}
        className={styles.linkedContentImage}
        height="375"
        width="640"
        sizes="(max-width: 767px) 375px, 640px"
        src={desktopSource.url()}
      />
    </picture>
  );
};

const ShedContent = ({ linkedContentArticle }) => {
  const { category, shortDescription, slug, title } = linkedContentArticle;

  return (
    <div className={styles.shedContentContainer}>
      <h2 className={styles.shedContentTitle}>{title}</h2>
      <p className={styles.shedContentText}>{shortDescription}</p>
      <Link
        className={cx(styles.shedContentText, styles.shedContentLink)}
        to={shedArticleRoute({
          category: category?.articleCategory.slug,
          slug,
        })}
      >
        Read article
      </Link>
    </div>
  );
};

const serializers = {
  marks: {
    link: ({ mark, children }) => {
      const { href, linkType } = mark;

      switch (linkType) {
        case 'articleLink':
          const { category, slug: articleSlug } = mark.articleLink;
          return (
            <Link
              to={shedArticleRoute({
                category: category.articleCategory.slug,
                slug: articleSlug,
              })}
            >
              {children}
            </Link>
          );

        case 'productLink':
          return (
            <ProductLink slug={mark.productLink.slug}>{children}</ProductLink>
          );

        case 'generalLink':
          let parsed;
          try {
            parsed = new URL(href);
          } catch (e) {
            captureException(
              new Error('Error parsing URL in LinkedContent', {
                extras: {
                  children,
                },
              })
            );
            return children;
          }

          if (parsed.host.includes('getsunday.com')) {
            return <Link to={parsed.pathname}>{children}</Link>;
          }

          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );

        case 'fileLink':
          return (
            <a
              href={assetUrlFor(mark.fileLink?.asset)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          );

        default:
          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
      }
    },
    small: ({ children }) => {
      return <small>{children}</small>;
    },
  },
};
