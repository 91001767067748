import React from 'react';
import cx from 'classnames';

import styles from '~/components/the-shed/components/article-block.module.scss';

/**
 *
 * @param {string} summary
 * @param {string} [className] - Optional className
 */
const ArticleBlockSummary = ({ summary, className }) => {
  return <p className={cx(styles.articleBlockSummary, className)}>{summary}</p>;
};

export { ArticleBlockSummary };
