import React from 'react';
import { TrendingItems as AlgoliaTrendingItems } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';

import LazyLoad from '~/components/lazy-load';
import SundayRelatedProducts from '~/components/sunday-store/components/product-details/sunday-related-products';
import {
  RecommendedProductsCarousel,
  RecommendedProduct,
  filterProducts,
} from '~/components/sunday-store/components/product-details/related-products';
import FullWidthSection from '~/components/static/full-width-section';
import { ALGOLIA_PRODUCTS_INDEX } from '~/components/search/shared';
import { IS_PRODUCTION } from '~/utils/environment';

import styles from '~/components/sunday-store/components/product-details/related-products.module.scss';

const algoliaRecommendClient = recommend(
  window.env.ALGOLIA_APP_ID,
  window.env.ALGOLIA_API_KEY
);

const TrendingItems = ({
  currentObjectID,
  productDetails,
  productsByCategory,
}) => {
  return (
    <section className={styles.relatedProducts}>
      <LazyLoad height={240}>
        {currentObjectID !== '' ? (
          <AlgoliaTrendingItems
            recommendClient={algoliaRecommendClient}
            indexName={ALGOLIA_PRODUCTS_INDEX}
            itemComponent={RecommendedProduct}
            view={RecommendedProductsCarousel}
            headerComponent={HeaderComponent}
            maxRecommendations={20}
            transformItems={filterProducts}
            fallbackComponent={() => (
              <SundayRelatedProducts
                productDetails={productDetails}
                productsByCategory={productsByCategory}
              />
            )}
          />
        ) : (
          <SundayRelatedProducts
            productDetails={productDetails}
            productsByCategory={productsByCategory}
          />
        )}
      </LazyLoad>
    </section>
  );
};

export default TrendingItems;

const HeaderComponent = () => {
  return <h2 className={styles.relatedProductsHeading}>Trending items</h2>;
};

export const HomepageTrendingItems = () => {
  if (!IS_PRODUCTION) {
    return null;
  }

  return (
    <FullWidthSection
      backgroundColor="var(--light-color)"
      innerClassName={styles.homepageTrendingItemsWrapper}
    >
      <section className={styles.homepageTrendingItems}>
        <LazyLoad height={240}>
          <AlgoliaTrendingItems
            recommendClient={algoliaRecommendClient}
            indexName={ALGOLIA_PRODUCTS_INDEX}
            itemComponent={RecommendedProduct}
            view={RecommendedProductsCarousel}
            headerComponent={HeaderComponent}
            maxRecommendations={20}
            transformItems={filterProducts}
          />
        </LazyLoad>
      </section>
    </FullWidthSection>
  );
};
