import React from 'react';
import { NavLink, useLocation } from 'react-router';
import cx from 'classnames';

import Button from '~/components/button';
import Dropdown from '~/components/navigation/desktop/dropdown';
import CartLink from '~/components/navigation/cart-link/cart-link';
import SearchWidget from '~/components/search/search-widget';
import SubNavMenu from '~/components/navigation/desktop/desktop-sub-nav-menu';
import { useSession } from '~/features/session/session-actions';
import { useLawn } from '~/features/lawns/lawn-actions';
import { useSubscriptionsForLawn } from '~/features/subscriptions/subscription-actions';
import { ProductCategories } from '~/features/products/products';
import { useFormAnswers } from '~/hooks/use-form-answers';
import ShedProgressBar from '~/components/the-shed/components/progress-bar';
import { getSignInUrl } from '~/utils/get-sign-in-url';
import * as r from '~/routes';

import styles from '~/components/navigation/desktop/desktop-nav.module.scss';

const DesktopNav = () => {
  const location = useLocation();
  const { user } = useSession();
  const { lawn, isFetched: isLawnFetched } = useLawn();
  const isSearchPage = window.location.pathname.includes('search');
  const isShedArticlePage = window.location.pathname.includes('/shed/');

  return (
    <header className={styles.navWrapper} data-testid="desktop-nav">
      <div className={cx('container', styles.navInner)}>
        <div className={styles.navTopRow}>
          <NavLink
            className={styles.logoLink}
            to={r.homeRoute()}
            aria-label="Home"
            data-heap-id="nav-home"
          >
            <img
              className={styles.logo}
              src="/images/logo-black.svg?v1"
              alt="Sunday Lawn & Garden Care"
              width="300"
              height="92"
            />
          </NavLink>

          {!isSearchPage && <SearchWidget />}

          <section className={styles.userGroup}>
            {user ? (
              <UserDropdown
                currentUser={user}
                lawnAddressLine={lawn?.address?.line1}
              />
            ) : (
              <NavLink
                className={styles.signInLink}
                to={getSignInUrl(location)}
                data-heap-id="nav-sign-in"
              >
                <span className={styles.underline}>
                  <p>Sign in</p>
                </span>
                <img
                  className={styles.avatar}
                  src="/icons/user-avatar-icon.svg"
                  alt="user icon"
                  width="28"
                  height="28"
                />
              </NavLink>
            )}
            <CartLink />
          </section>
        </div>

        <nav
          className={cx(styles.navGroup, styles.navWithButton)}
          aria-label="Main menu"
        >
          <ul className={styles.linkList}>
            <li>
              <NavLink
                className={styles.link}
                to={r.customLawnPlanRoute()}
                data-heap-id="nav-custom-lawn-plan"
              >
                Custom lawn plan
              </NavLink>
            </li>
            <li>
              <NavLink
                className={styles.link}
                to={r.customPestPlanRoute()}
                data-heap-id="nav-custom-pest-plan"
              >
                Custom pest plan
              </NavLink>
            </li>
            <li>
              <NavLink
                className={styles.link}
                to={r.categoryPageRoute(ProductCategories.LAWN_CARE.slug)}
                data-heap-id="nav-category-shop-lawn-care"
              >
                Lawn care
              </NavLink>
            </li>
            <li>
              <NavLink
                className={styles.link}
                to={r.categoryPageRoute(ProductCategories.PEST_CONTROL.slug)}
                data-heap-id="nav-category-shop-pest-control"
              >
                Pest control
              </NavLink>
            </li>
            <li>
              <NavLink
                className={styles.link}
                to={r.categoryPageRoute(ProductCategories.GARDEN.slug)}
                data-heap-id="nav-category-garden"
              >
                Garden
              </NavLink>
            </li>
            <li>
              <SubNavMenu selectedMenu={'SHED_CONTENT'} />
            </li>
          </ul>
        </nav>
        <LawnPlanCta
          lawnUuid={lawn?.uuid}
          isLawnFetched={isLawnFetched}
          user={user}
        />
      </div>
      {isShedArticlePage && <ShedProgressBar />}
    </header>
  );
};

export default DesktopNav;

function UserDropdown({ currentUser, lawnAddressLine }) {
  const { signOut } = useSession();

  const userHasCompleteLawn = currentUser && lawnAddressLine; // if a Lawn has a `line1` field in its `address`, they can access the Lawn Data pages without issue

  const buttonContent = (
    <div className={styles.signInLink}>
      <span className={styles.underline}>
        <p style={{ fontWeight: '700' }}>
          {currentUser.firstName ? `Hello, ${currentUser.firstName}` : 'Hello'}
        </p>
      </span>
      <img
        className={styles.avatar}
        src="/icons/user-avatar-icon.svg"
        alt="user icon"
        width="28"
        height="28"
      />
    </div>
  );

  return (
    <Dropdown
      buttonContent={buttonContent}
      buttonClassName={cx(styles.accountDropdown)}
      buttonClassNameOpen={styles.activeLink}
      to={r.accountSettingsRoute()}
      variant="account"
    >
      <NavLink
        className={styles.accountLink}
        to={r.myPlanRoute()}
        data-heap-id="nav-account-my-plan"
      >
        <span className={styles.underline}>My plan</span>
      </NavLink>
      {userHasCompleteLawn && (
        <NavLink
          className={styles.accountLink}
          to={r.lawnDataRoute()}
          data-heap-id="nav-account-lawn-data"
        >
          <span className={styles.underline}>Soil test results</span>
        </NavLink>
      )}
      {currentUser?.hasUnpaidOrder && (
        <NavLink
          className={styles.accountLink}
          to={r.nextBoxRoute()}
          data-heap-id="nav-account-next-order"
        >
          <span className={styles.underline}>Manage next box</span>
        </NavLink>
      )}
      <NavLink
        className={styles.accountLink}
        to={r.accountSettingsRoute()}
        data-heap-id="nav-account-account"
      >
        <span className={styles.underline}>Account</span>
      </NavLink>

      <NavLink
        className={styles.accountLink}
        to={r.referralLandingPageRoute()}
        data-heap-id="nav-account-refer"
      >
        <span className={styles.underline}>Refer a friend</span>
      </NavLink>

      <NavLink
        className={styles.accountLink}
        to={r.helpCenterRoute()}
        data-heap-id="nav-account-help"
      >
        <span className={styles.underline}>Need help?</span>
      </NavLink>

      <div className={styles.accountDropdownFooter}>
        <button
          className={styles.accountLink}
          onClick={signOut}
          type="button"
          data-heap-id="nav-account-sign-out"
        >
          <span className={styles.underline}>Sign out</span>
        </button>
      </div>
    </Dropdown>
  );
}

export const LawnPlanCta = ({
  className,
  isMobile,
  lawnUuid,
  handleClick,
  isLawnFetched,
  user,
}) => {
  const { formAnswers, isLoading: isLoadingFormAnswers } = useFormAnswers();
  const hasAnsweredLawnQuiz = formAnswers?.LAWN_FUNNEL?.length > 0;

  const {
    isCurrentLawnSubscriber,
    isLoading: isLoadingSubscriptions,
    isFetched: areSubscriptionsFetched,
  } = useSubscriptionsForLawn(lawnUuid);

  const isLoading =
    user &&
    (!isLawnFetched ||
      isLoadingFormAnswers ||
      (lawnUuid && (isLoadingSubscriptions || !areSubscriptionsFetched)));

  if (isLoading) {
    return null;
  }

  let link = r.customLawnPlanRoute();
  let ctaCopy = 'Get lawn analysis';

  if (!isCurrentLawnSubscriber && hasAnsweredLawnQuiz) {
    ctaCopy = 'See my plan';
    link = r.planDetailsRoute(lawnUuid);
  } else if (isCurrentLawnSubscriber) {
    ctaCopy = 'See my plan';
    link = r.myPlanRoute();
  }

  return (
    <Button
      className={cx(styles.lawnCtaButton, className)}
      fullWidth={isMobile}
      onClick={handleClick}
      to={link}
      variant="yellow"
      data-heap-id="nav-lawn-funnel-cta"
    >
      {ctaCopy}
    </Button>
  );
};
