export const ProductCategories = {
  LAWN_CARE: {
    slug: 'lawn-care',
    label: 'Lawn Care',
  },
  GARDEN: {
    slug: 'garden',
    label: 'Garden',
  },
  PEST_CONTROL: {
    slug: 'pest-control',
    label: 'Pest Control',
  },
  PRO_SUPPLY: {
    slug: 'pro-supply',
    label: 'Pro Supply',
  },
  FULL_SERVICE: {
    slug: 'full-service',
    label: 'Full Service',
  },
};

export const livePlantSubcategories = ['trees', 'shrubs-bushes'];

export const isLivePlantProduct = (product) => {
  return livePlantSubcategories.includes(
    product?.productDetails?.categories?.[0]?.subCategory?.slug
  );
};

export const seedSubcategorySlug = 'grass-seed';

export const isSoilTestKit = (productDetails) => {
  return productDetails?.categories?.[0]?.subCategory?.slug === 'soil-test-kit';
};

export const canSeeQtyField = (
  productDetails,
  isPlanOrPlanProduct = false,
  isInStock = true
) => {
  return (
    !isSoilTestKit(productDetails) &&
    !isPlanOrPlanProduct &&
    isInStock &&
    !UNIQUE_CART_ITEMS.includes(productDetails?.sku)
  );
};

// products that we only want to allow the user to add once to their cart
export const UNIQUE_CART_ITEMS = ['SBA3007', 'SBA3024'];

/**
 *  Filters out Pro Supply and Full Service products from the search results.
 */
export const productFilter = `NOT categories:${ProductCategories.PRO_SUPPLY.slug} AND NOT categories:${ProductCategories.FULL_SERVICE.slug}`;
